/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";

import Map from "views/Map.js";

import Rtl from "views/Rtl.js";

import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import ABK from "views/ABK";

import ProgresUpload from "views/ProgresUpload";
import Sakip from "views/Sakip";

import Upload_Dok from "views/Upload_Dok";
import Upload_Tab from "views/Upload_Tab";
import UsersManek from "views/UsersManek";
import Dokumen from "views/Dokumen";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
    sidebar: true,
    user: true,
  },
  {
    path: "/indikator",
    name: "Indikator",
    rtlName: "إخطارات",
    icon: "tim-icons icon-bell-55",
    component: Dokumen,
    layout: "/admin",
    sidebar: true,
    user: true,
  },

  {
    path: "/upload",
    name: "Upload Dokumen",
    rtlName: "إخطارات",
    icon: "tim-icons icon-bell-55",
    component: Upload_Tab,
    layout: "/admin",
    sidebar: true,
    user: true,
  },
  {
    path: "/dokumen",
    name: "Dokumen SatuData",
    rtlName: "إخطارات",
    icon: "tim-icons icon-bell-55",
    component: Upload_Dok,
    layout: "/admin",
    sidebar: false,
    user: true,
  },

  {
    path: "/progres",
    name: "Progres Upload",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-puzzle-10",
    component: ProgresUpload,
    layout: "/admin",
    sidebar: true,
    user: true,
  },

  {
    path: "/users-satudata",
    name: "Users SatuData",
    rtlName: "ملف تفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: UsersManek,
    layout: "/admin",
    sidebar: true,
    user: false,
  },
  {
    path: "/user-profile",
    name: "User Profile",
    rtlName: "ملف تفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/admin",
    sidebar: true,
    user: true,
  },
];
export default routes;
