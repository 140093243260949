import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Progress,
  InputGroup,
  InputGroupAddon,
  Spinner,
} from 'reactstrap';

import axios from 'axios';
import { useState, useEffect } from 'react';
import { PERANGKAT_DAERAH, API_TIMEOUT } from 'utils';
import { toProperCase } from 'utils/func';
import { USERS } from 'utils';
import FIREBASE from '../config/FIREBASE';
import swal from 'sweetalert';
import { logApp } from 'utils/func';
import { useHistory } from 'react-router-dom';
import { refreshToken } from 'utils/func';

function UsersManek() {
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [dokumen, setDokumen] = useState('');

  const [status, setStatus] = useState('');
  const [username, setUsername] = useState('');
  const [nama, setNama] = useState('');

  const [url, setUrl] = useState('');
  const [uid, setUid] = useState('');
  const [cari, setCari] = useState(false);
  const [gambar, setGambar] = useState('');
  const [tambah, setTambah] = useState(true);
  const [searchItem, setSearchItem] = useState('');
  const [id_unit, setId_unit] = useState('');
  const [listPd, setListPd] = useState([]);

  const [currentUser, setCurrentUser] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem('user');
      if (user) {
        user = JSON.parse(user);
        logApp(user.uid, 'User Manek');
        setCurrentUser(user);
      } else {
        history.push('/login');
      }
    }
    refreshToken(history, setAccessToken);
  }, []);

  const onChangeDok = (e) => {
    setDokumen(e.target.files[0]);
  };

  useEffect(() => {
    if (currentUser && accessToken) {
      ambilUsers();
      ambilPerangkatDaerah();
    }
  }, [currentUser, accessToken]);

  const handleSimpan = () => {
    if (!nama || !username || !status || !id_unit) {
      swal('Gagal', 'Data yang anda masukan harus lengkap', 'error');
      return;
    }
    setSaveLoading(true);
    simpanAdmin();
  };

  const simpanAdmin = async () => {
    const formData = new FormData();

    formData.append('photo', gambar);

    formData.append('id_Smart', uid);
    formData.append('id_Lokasi', id_unit);
    formData.append('username', username);
    formData.append('status', status);

    formData.append('nama', nama);
    formData.append('uid', currentUser.uid);

    await axios({
      method: 'post',
      url: USERS + 'tambah',
      timeout: API_TIMEOUT,
      data: formData,
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          swal('Failed', 'ada kesalahan pada server', 'error');
        } else {
          //BERHASIL
          swal('Success', 'User berhasil ditambahkan', 'success');
          setSaveLoading(false);
          ambilUsers();
          onCloseModal();
        }
      })
      .catch((error) => {
        // ERROR
        setSaveLoading(false);
        swal('Failed', 'Ada kesalahan pada server', 'error');
      });
  };

  const convertGambar = (avatar) => {
    // Data gambar dalam format data URI
    if (avatar) {
      const imageDataURI = avatar; // Sisipkan data gambar lengkap di sini

      // Memisahkan data URI menjadi tipe dan base64 encoded data
      const [dataType, base64Data] = imageDataURI.split(',');

      // Mengubah base64 encoded data menjadi bentuk binary
      const binaryData = atob(base64Data);

      // Mendapatkan panjang data binary
      const len = binaryData.length;

      // Membuat array untuk menyimpan data binary dalam bentuk Uint8Array
      const uint8Array = new Uint8Array(len);

      // Mengisi array dengan data binary
      for (let i = 0; i < len; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      // Membuat file baru dari Uint8Array dan menambahkan tipe datanya
      const file = new File([uint8Array], 'gambar.jpg', { type: dataType });

      setGambar(file);
    } else {
      setGambar('');
    }
  };

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: 'get',
      url: PERANGKAT_DAERAH,
      timeout: API_TIMEOUT,
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          setListPd(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilUsers = (id) => {
    axios({
      method: 'get',
      url: USERS,
      timeout: API_TIMEOUT,
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const getUserEmail = (email) => {
    FIREBASE.database()
      .ref('users')
      .orderByChild('email')
      .equalTo(email)
      .once('value', (querySnapshot) => {
        //hasil disini

        let data = querySnapshot.val() ? querySnapshot.val() : [];

        let arrayOfNames = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const nama = data[key];
            arrayOfNames.push(nama);
          }
        }

        if (arrayOfNames.length > 0) {
          setCari(false);
          setNama(arrayOfNames[0].nama);
          setUsername(arrayOfNames[0].email);

          //  setDinas(arrayOfNames[0].unitKerja);
          setUrl(arrayOfNames[0].avatar);
          setUid(arrayOfNames[0].uid);
          convertGambar(arrayOfNames[0].avatar);
        } else {
          setCari(false);
          setNama('');
          setUsername('');
          //  setDinas(arrayOfNames[0].unitKerja);
          setUrl('');
          setUid('');
          convertGambar('');
          swal('Gagal', 'Username tidak ditemukan', 'error');
        }
      })
      .catch((error) => {
        setCari(false);
        swal('Gagal', 'Username tidak ditemukan', 'failed');
      });
  };

  const cariUser = (email) => {
    setCari(true);
    getUserEmail(email);
  };

  const onCloseModal = () => {
    setNama('');
    setUsername('');
    setUid('');
    setUrl('');
    setGambar('');
    setSearchItem('');
    setId_unit('');
    setModalOpen(false);
  };

  const onModalClose2 = () => {
    setNama('');
    setUid('');
    setModalOpen2(false);
  };

  const handleDelete = async () => {
    const response = await axios.delete(
      USERS + uid,

      {
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data.success === 1) {
      swal('Sukses', 'Data Admin ' + nama + ' berhasil dihapus', 'success');
      ambilUsers();

      onModalClose2();
    } else {
    }
  };

  return (
    <>
      <div className='content'>
        <Row>
          <Col md='12'>
            <Card>
              <CardHeader
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CardTitle tag='h4'>Pengguna SatuData</CardTitle>
                <Button color='danger' onClick={() => setModalOpen(true)}>
                  Tambah User
                </Button>
              </CardHeader>

              <CardBody>
                <Table className='tablesorter' responsive>
                  <thead className='text-primary'>
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>Perangkat Daerah</th>
                      <th>Email</th>
                      <th className='text-center'>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelPerangkatDaerah &&
                      tabelPerangkatDaerah.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.nama}</td>
                          <td>{toProperCase(item.perangkatdaerah)}</td>
                          <td style={{ cursor: 'pointer' }}>{item.username}</td>
                          <td className='text-center' style={{ width: 100 }}>
                            {item.status == 1 ? 'admin' : 'user'}
                          </td>
                          <td>
                            <div
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Button
                                size='sm'
                                color='primary'
                                style={{ marginRight: 5 }}
                              >
                                Edit
                              </Button>
                              <Button
                                size='sm'
                                color='danger'
                                onClick={() => {
                                  setNama(item.nama);
                                  setUid(item.id_Smart);
                                  setModalOpen2(true);
                                }}
                              >
                                delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size='md'
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          contentClassName='p-0'
          modalClassName='modal-slide-in event-sidebar'
        >
          <ModalHeader toggle={() => setModalOpen(false)}>
            <div>
              <div className='headline-2'>{'Tambah User'}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            {tambah && (
              <FormGroup>
                <InputGroup>
                  <Input
                    type='text'
                    id='search-input1'
                    value={searchItem}
                    style={{ color: 'black' }}
                    onChange={(e) => setSearchItem(e.target.value)}
                  />
                  <InputGroupAddon addonType='append'>
                    {cari ? (
                      <Button color='default' disabled>
                        <Spinner size='sm' color='white' /> Loading
                      </Button>
                    ) : (
                      <Button
                        color='default'
                        onClick={() => cariUser(searchItem)}
                      >
                        Search
                      </Button>
                    )}
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            )}
            {url && (
              <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={url ? url : ''}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 50,

                    marginBottom: 10,
                    border: '2px solid blue',
                  }}
                />
              </Row>
            )}

            {nama && (
              <div>
                <FormGroup row>
                  <Label md={3} for='normal-field' className='text-md-right'>
                    Nama
                  </Label>
                  <Col md={9}>
                    <Input
                      type='text'
                      id='default-textarea'
                      placeholder='nama'
                      value={nama}
                      style={{ color: 'black' }}
                      onChange={(e) => setNama(e.target.value)}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3} for='normal-field' className='text-md-right'>
                    Username
                  </Label>
                  <Col md={9}>
                    <Input
                      type='text'
                      id='default-textarea'
                      placeholder='username'
                      style={{ color: 'black' }}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3} for='normal-field' className='text-md-right'>
                    Peran
                  </Label>
                  <Col md={9}>
                    <Input
                      type='select'
                      id='normal-field'
                      style={{ color: 'black' }}
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value={''}>- pilih - </option>
                      <option value={1}>admin</option>
                      <option value={2}>user </option>
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md={3} for='normal-field' className='text-md-right'>
                    Perangkat Daerah
                  </Label>
                  <Col md={9}>
                    <Input
                      type='select'
                      id='normal-field'
                      value={id_unit}
                      style={{ color: 'black' }}
                      onChange={(e) => setId_unit(e.target.value)}
                    >
                      <option value={''}>-pilih-</option>
                      {listPd.map((item) => (
                        <option value={item.id_unit}>{item.nama}</option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </div>
            )}
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center', paddingBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Button
                className='btn-rounded'
                size='sm'
                color='secondary-red'
                onClick={onCloseModal}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              {!saveLoading ? (
                <Button
                  className='btn-rounded'
                  color='primary'
                  size='sm'
                  onClick={() => handleSimpan()}
                >
                  {' '}
                  {'Simpan'}
                </Button>
              ) : (
                <Button className='btn-rounded' size='sm'>
                  {'Simpan ...'}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          size='md'
          isOpen={modalOpen2}
          toggle={() => onModalClose2()}
          contentClassName='p-0'
          modalClassName='modal-slide-in event-sidebar'
        >
          <ModalHeader toggle={() => onModalClose2()}>
            <div>
              <div className='headline-2'>Konfirmasi hapus akun</div>
            </div>
          </ModalHeader>
          <ModalBody>
            Apakah anda ingin menghapus akun atas nama {nama} ?
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center', paddingBottom: 10 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Button
                className='btn-rounded'
                size='sm'
                color='secondary-red'
                onClick={() => onModalClose2()}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              <Button
                className='btn-rounded'
                color='primary'
                size='sm'
                onClick={handleDelete}
              >
                Hapus
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default UsersManek;
