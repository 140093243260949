import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Badge,
} from "reactstrap";
import { useEffect, useState } from "react";
import axios from "axios";

import { Document, Page } from "react-pdf";

import { PERANGKAT_DAERAH, API_TIMEOUT } from "utils";
import { toProperCase } from "utils/func";
import { logApp } from "utils/func";
import { useHistory } from "react-router-dom";
import { DOKUMEN } from "utils";
import { GET_DOKUMEN } from "utils";

function Upload_Dok() {
  const domain = "https://satudata.rotendaokab.go.id/uploads/";
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [currentUser, setCurrentUser] = useState("");
  const [listDokumen, setListDokumen] = useState([]);
  const [tahun, setTahun] = useState(2023);

  const history = useHistory();

  const [dokumen, setDokumen] = useState("1");

  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        logApp(user.uid, "Dok ABK");
        setCurrentUser(user);
      } else {
        history.push("/login");
      }
    }
  }, []);

  useEffect(() => {
    ambilDokumen();
    ambilListDokumen();
  }, []);

  useEffect(() => {
    if (tahun && dokumen) {
      ambilDokumen();
    }
  }, [tahun, dokumen]);

  const ambilDokumen = (id) => {
    axios({
      method: "post",
      url: GET_DOKUMEN,
      timeout: API_TIMEOUT,
      data: {
        tahun: tahun,
        status: dokumen,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          const data = response.data.data;
          setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const openPDF = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  const ambilListDokumen = (id) => {
    axios({
      method: "get",
      url: DOKUMEN,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          setListDokumen(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h5 className="title">
                    Dokumen Terupload{" "}
                    <Badge color="danger">{tabelPerangkatDaerah.length}</Badge>
                  </h5>{" "}
                  <p className="category">Daftar Perangkat Daerah </p>
                </div>
                <div>
                  <Input
                    type="select"
                    name="liga"
                    value={dokumen}
                    onChange={(event) => {
                      setDokumen(event.target.value);
                    }}
                  >
                    <option value={""}>- pilih -</option>
                    {listDokumen.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.dokumen}
                      </option>
                    ))}
                  </Input>
                </div>
                <div>
                  <Input
                    type="select"
                    style={{ color: "white", width: 100 }}
                    onChange={(event) => {
                      setTahun(event.target.value);
                    }}
                  >
                    <option value={2020}>2020</option>
                    <option value={2021}>2021</option>
                    <option value={2022}>2022</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                  </Input>
                </div>
              </CardHeader>
              <CardBody className="all-icons">
                <Row>
                  {tabelPerangkatDaerah &&
                    tabelPerangkatDaerah.map((item) => (
                      <Col
                        className="font-icon-list col-xs-6 col-xs-6"
                        lg="2"
                        md="3"
                        sm="4"
                      >
                        <div
                          className="font-icon-detail"
                          style={{ cursor: "pointer" }}
                          onClick={() => openPDF(domain + item.dokumen)}
                        >
                          <i className="tim-icons icon-single-copy-04" />
                          <p>{toProperCase(item.perangkatdaerah)}</p>
                        </div>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size="lg"
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          className="sidebar-lg"
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen(false)}>
            <div>
              <div className="headline-2">{"Dokumen Analisis Jabatan"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <div>
                <h1>Viewer PDF</h1>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              <Button className="btn-rounded" color="primary" size="sm">
                {" "}
                {"Simpan"}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Upload_Dok;
