import React, { useState } from "react";
import Logo from "../../assets/img/logorote.png";
import { getWindowWidth } from "utils/func";

const Header = ({ setModalOpen }) => {
  const [lebarJendela, setLebarJendela] = useState(window.innerWidth);

  function reportWindowSize() {
    setLebarJendela(window.innerWidth);
  }

  // Menambahkan event listener untuk resize
  window.addEventListener("resize", reportWindowSize);

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "transparent", // Background transparan
  };

  const logoStyle = {
    fontWeight: "bold",
    color: "#d9534f", // Warna teks logo
    fontSize: "24px", // Sesuaikan dengan ukuran yang diinginkan
    display: "flex",
    alignItems: "center",
  };

  const logoTextStyle = {
    marginLeft: "10px", // Jarak antara logo dan teks
  };

  const navStyle = {
    listStyle: "none",
    display: "flex",
    margin: 0,
    padding: 0,
  };

  const navItemStyle = {
    margin: "0 10px",
    color: "#333", // Warna teks navigasi
    textDecoration: "none",
    fontSize: "16px", // Sesuaikan dengan ukuran yang diinginkan
    cursor: "pointer",
  };

  const buttonStyle = {
    backgroundColor: "#d9534f", // Warna background tombol
    color: "white", // Warna teks tombol
    border: "none",
    borderRadius: "20px", // Bulatkan sudut tombol
    padding: "10px 20px",
    cursor: "pointer",
    outline: "none",
  };

  return (
    <header style={headerStyle}>
      <div style={logoStyle}>
        {/* Sisipkan logo sebagai gambar atau SVG di sini */}
        <img src={Logo} style={{ width: 40 }} />
        {lebarJendela > 800 && <span style={logoTextStyle}>SATU DATA</span>}
      </div>
      {lebarJendela > 800 && (
        <nav>
          <ul style={navStyle}>
            <li style={navItemStyle}>Dataset</li>
            <li style={navItemStyle}>Data Insight</li>
            <li style={navItemStyle}>Publikasi</li>
            <li style={navItemStyle}>Tentang</li>
            {/* Tambahkan elemen navigasi lain jika diperlukan */}
          </ul>
        </nav>
      )}
      <button
        style={buttonStyle}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Masuk
      </button>
    </header>
  );
};

export default Header;
