/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from "variables/charts.js";
import { API_TIMEOUT } from "utils";
import { EFEKTIFITAS } from "utils";
import axios from "axios";
import { USERS } from "utils";
import { toProperCase } from "utils/func";
import { useHistory } from "react-router-dom";
import { logApp } from "utils/func";
import { GET_NOTIF } from "utils";
import { LOGIN_USER } from "actions/AuthAction";
import { formatDate } from "utils/func";
import { GET_AKTIVITY } from "utils";
import { formatDateTime } from "utils/func";
import { logoutUser } from "actions/AuthAction";
import { ADMINS_TOKEN } from "utils";
import jwt_decode from "jwt-decode";

import Map from "../components/am4chartMap/am4chartMap";

function Dashboard(props) {
  const [bigChartData, setbigChartData] = React.useState("data1");

  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [label, setLabel] = useState([]);
  const [dataGraph1, setDataGraph1] = useState({ label: [], dataGraph: [] });
  const [dataGraph2, setDataGraph2] = useState({ label: [], dataGraph: [] });
  const [dataGraph3, setDataGraph3] = useState({ label: [], dataGraph: [] });
  const [penggunaManek, setPenggunaManek] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const history = useHistory();
  const [notif, setNotif] = useState([]);
  const [accessToken, setAccessToken] = useState("");

  const [expire, setExpire] = useState("");

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "Dashboard");
      } else {
        history.push("/login");
      }
    }
    // refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(ADMINS_TOKEN, {
        credentials: "include",
      });
      const decoded = jwt_decode(response.data.accessToken);

      setAccessToken(response.data.accessToken);

      setExpire(decoded.exp);
    } catch (error) {
      logoutUser(history);
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ADMINS_TOKEN);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;

        const decoded = jwt_decode(response.data.accessToken);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    ambilEfektifitas();
    // ambilPengguna();
    // ambilNotif();
  }, []);

  const ambilNotif = (id) => {
    axios({
      method: "get",
      url: GET_NOTIF,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setNotif(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilPengguna = (id) => {
    axios({
      method: "get",
      url: GET_AKTIVITY,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setPenggunaManek(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };
  const ambilEfektifitas = (id) => {
    axios({
      method: "get",
      url: EFEKTIFITAS,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          let data1 = data;
          data1.sort((a, b) => b.efektifitas - a.efektifitas);

          data1 = data1.slice(0, 10);

          for (let i = data1.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [data1[i], data1[j]] = [data1[j], data1[i]];
          }

          const label1 = data1.map((item) => {
            return item.singk;
          });
          const dataGraph1 = data1.map((item) => {
            return item.efektifitas;
          });

          setDataGraph1({ label: label1, dataGraph: dataGraph1 });

          let data2 = data;

          data2.sort((a, b) => a.efektifitas - b.efektifitas);
          data2 = data2.slice(0, 10);

          for (let i = data2.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [data2[i], data2[j]] = [data2[j], data2[i]];
          }

          const label2 = data2.map((item) => {
            return item.singk;
          });
          const dataGraph2 = data2.map((item) => {
            return item.efektifitas;
          });

          setDataGraph2({ label: label2, dataGraph: dataGraph2 });

          // Mengacak urutan data menggunakan algoritma Fisher-Yates Shuffle

          let data3 = response.data.data;

          data3 = data3.slice(0, 10);
          for (let i = data3.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [data3[i], data3[j]] = [data3[j], data3[i]];
          }

          const label3 = data3.map((item) => {
            return item.singk;
          });
          const dataGraph3 = data3.map((item) => {
            return item.efektifitas;
          });

          setDataGraph3({ label: label3, dataGraph: dataGraph3 });

          setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  let chart1_2_options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
    },
  };

  let chartExample1 = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: dataGraph1.label,
        datasets: [
          {
            label: "Efektifitas Top 10",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph1.dataGraph,
          },
        ],
      };
    },
    data2: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: dataGraph2.label,
        datasets: [
          {
            label: "Efektifitas Bottom 10",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph2.dataGraph,
          },
        ],
      };
    },
    data3: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: dataGraph3.label,
        datasets: [
          {
            label: "Efektifitas Random",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph3.dataGraph,
          },
        ],
      };
    },
    options: chart1_2_options,
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">Jumlah Penduduk</h5>
                    <CardTitle tag="h2">Per Kecamatan</CardTitle>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Tertinggi
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Terendah
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Random
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-tap-02" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample1[bigChartData]}
                    options={chart1_2_options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">APBD</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-bell-55 text-info" /> Rp.763,215
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">PDRB</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-delivery-fast text-primary" />{" "}
                  Rp.3,500.000
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={chartExample3.data}
                    options={chartExample3.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Belanja</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-send text-success" /> Rp.12,100
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample4.data}
                    options={chartExample4.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <Map />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
