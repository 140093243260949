import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom"; // Ubah BrowserRouter ke HashRouter

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import Login from "views/Login";
import { Provider } from "react-redux";
import store from "reducers/store";
import LandingPage from "views/LandingPage";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
        <HashRouter>
          {" "}
          {/* Ganti BrowserRouter dengan HashRouter di sini */}
          <Switch>
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
            <Route path="/login" component={LandingPage} exact />
            <Redirect from="/" to="/login" />
          </Switch>
        </HashRouter>{" "}
        {/* Tutup HashRouter di sini */}
      </BackgroundColorWrapper>
    </ThemeContextWrapper>
  </Provider>
);
