/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Progress,
} from "reactstrap";

import axios from "axios";
import { useState, useEffect } from "react";
import { PERANGKAT_DAERAH, API_TIMEOUT } from "utils";
import { toProperCase } from "utils/func";
import { useHistory } from "react-router-dom";
import { logApp } from "utils/func";
import { refreshToken } from "utils/func";
import { ANALISIS_BEBAN_KERJA } from "utils";
import { GET_DOKUMEN_ALL } from "utils";
import swal from "sweetalert";
import { POST_DOKUMEN } from "utils";

function Dokumen() {
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [listPD, setListPD] = useState([]);

  const [id_unit, setId_Unit] = useState("");

  const [ekisting, setEksisting] = useState("");
  const [perangkatdaerah, setPerangkatDaerah] = useState("");
  const history = useHistory();

  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const [dokumen, setDokumen] = useState("");
  const [siklus, setSiklus] = useState("");

  const [batas, setBatas] = useState("");

  const [catatan, setCatatan] = useState("");

  const [status, setSatatus] = useState("");
  const [idPd, setIdPd] = useState("");

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        logApp(user.uid, "Peta Jabatan");
        setCurrentUser(user);
      } else {
        history.push("/login");
      }
    }
    //refreshToken(history, setAccessToken);
  }, []);

  useEffect(() => {
    if (currentUser) {
      ambilPerangkatDaerah();
      ambilPD();
    }
  }, [currentUser]);

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: "get",
      url: GET_DOKUMEN_ALL,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          const data = response.data.data;
          setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilPD = () => {
    axios({
      method: "get",
      url: PERANGKAT_DAERAH,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          const data = response.data.data;
          setListPD(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleSimpan = () => {
    if (!dokumen || !idPd) {
      return swal("Gagal", "Belum semua data diinput", "error");
    }

    axios({
      method: "post",
      url: POST_DOKUMEN,
      timeout: API_TIMEOUT,
      credentials: "include",
      data: {
        dokumen: dokumen,
        idPd: idPd,
        siklus: siklus,
        batas: batas,
        catatan: catatan,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          ambilPerangkatDaerah();
          onModalClose();
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const onModalClose = () => {
    setId_Unit("");
    setPerangkatDaerah("");
    setEksisting("");
    setDokumen("");
    setSiklus("");
    setBatas("");
    setIdPd("");

    setModalOpen(false);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">Indikator</CardTitle>
                <Button color="danger" onClick={() => setModalOpen(true)}>
                  Tambah
                </Button>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Perangkat daerah</th>

                      <th>Indikator</th>
                      <th>Siklus Dokumen</th>
                      <th>Batas Input</th>
                      <th>Catatan </th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelPerangkatDaerah &&
                      tabelPerangkatDaerah.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            {item.perangkatdaerah &&
                              toProperCase(item.perangkatdaerah)}
                          </td>
                          <td>{item.dokumen}</td>

                          <td
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            {item.siklus}
                          </td>
                          <td className="text-center">{item.batas}</td>
                          <td className="text-center">{item.catatan}</td>

                          <td>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                "";
                              }}
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size="md"
          isOpen={modalOpen}
          toggle={() => onModalClose()}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => onModalClose()}>
            <div>
              <div className="headline-2">Tambah Indikator</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label lg="4" className="text-md-right">
                Nama Indikator
              </Label>
              <Col lg="8">
                <Input
                  id="upload"
                  type="text"
                  value={dokumen}
                  onChange={(e) => setDokumen(e.target.value)}
                  style={{ color: "black" }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg="4" className="text-md-right">
                siklus
              </Label>
              <Col lg="8">
                <Input
                  id="upload"
                  type="text"
                  value={siklus}
                  onChange={(e) => setSiklus(e.target.value)}
                  style={{ color: "black" }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg="4" className="text-md-right">
                Batas Penginputan (Bulan)
              </Label>
              <Col lg="8">
                <Input
                  id="upload"
                  type="text"
                  value={batas}
                  onChange={(e) => setBatas(e.target.value)}
                  style={{ color: "black" }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg="4" className="text-md-right">
                Catatan
              </Label>
              <Col lg="8">
                <Input
                  id="upload"
                  type="text"
                  value={catatan}
                  onChange={(e) => setCatatan(e.target.value)}
                  style={{ color: "black" }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg="4" className="text-md-right">
                Perangkat Daerah
              </Label>
              <Col lg="8">
                <Input
                  id="upload"
                  type="select"
                  value={idPd}
                  onChange={(e) => setIdPd(e.target.value)}
                  style={{ color: "black" }}
                >
                  <option value={""}>-Pilih-</option>
                  {listPD.map((item) => (
                    <option key={item.id_unit} value={item.id_unit}>
                      {item.nama}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => onModalClose()}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              <Button
                className="btn-rounded"
                color="primary"
                size="sm"
                onClick={() => handleSimpan()}
              >
                {" "}
                {"Simpan"}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Dokumen;
