import React, { useState } from "react";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Implementasikan logika pencarian di sini
    console.log(`Pencarian untuk: ${searchTerm}`);
  };

  // Style untuk search bar container
  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "20px",
    padding: "5px 10px",
    width: "100%", // Atau ukuran spesifik jika diperlukan
    boxSizing: "border-box", // Menjaga padding dan border agar tidak menambah lebar
  };

  // Style untuk input field
  const searchInputStyle = {
    border: "none",
    padding: "10px",
    width: "100%",
    outline: "none",
    fontSize: "16px",
    color: "#495057", // Warna teks yang sesuai dengan placeholder
  };

  // Style untuk search button
  const searchButtonStyle = {
    background: "none",
    border: "none",
    padding: "5px",
    cursor: "pointer",
    outline: "none",
    color: "red", // Warna dari ikon search Anda
    fontSize: "24px", // Sesuaikan ukuran sesuai dengan desain Anda
  };

  // Style untuk dropdown
  const selectStyle = {
    border: "none",
    background: "white",
    margin: "0 5px",
    padding: "5px 10px",
    cursor: "pointer",
    outline: "none",
    fontSize: "16px",
    color: "#495057", // Warna teks dropdown
    appearance: "none", // Menghilangkan style default
    WebkitAppearance: "none", // Safari
    MozAppearance: "none", // Firefox
  };

  return (
    <form onSubmit={handleSubmit} style={searchBarStyle}>
      <select style={selectStyle}>
        <option value="all">Semua Data</option>
        {/* Tambahkan opsi lain jika diperlukan */}
      </select>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Cari Data Publik"
        style={searchInputStyle}
      />
      <button type="submit" style={searchButtonStyle}>
        🔍
      </button>
    </form>
  );
};

export default SearchBar;
