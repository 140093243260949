import axios from "axios";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { LOG_APP } from "utils";
import { logoutUser } from "actions/AuthAction";
import { ADMINS_TOKEN } from "utils";

export const dateTime = (waktu) => {
  const hari = {
    0: "Minggu",
    1: "Senin",
    2: "Selasa",
    3: "Rabu",
    4: "Kamis",
    5: "Jumat",
    6: "Sabtu",
  };

  const bulan = {
    0: "Januari",
    1: "Februari",
    2: "Maret",
    3: "April",
    4: "Mei",
    5: "Juni",
    6: "Juli",
    7: "Agustus",
    8: "September",
    9: "Oktober",
    10: "November",
    11: "Desember",
  };

  const bulan2 = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "Mei",
    5: "Jun",
    6: "Jul",
    7: "Agu",
    8: "Sep",
    9: "Okt",
    10: "Nov",
    11: "Des",
  };
  var tanggal = new Date(waktu);
  var month = bulan2[tanggal.getMonth()];
  var day = hari[tanggal.getDay()];

  var date = tanggal.getDate().toString();
  if (date.length == 1) {
    date = "0" + date;
  }
  var year = tanggal.getFullYear();
  var jam = tanggal.getHours().toString();

  if (jam.length == 1) {
    jam = "0" + jam;
  }

  var minutes = tanggal.getMinutes().toString();
  if (minutes.length == 1) {
    minutes = "0" + minutes;
  }
  var waktukKu = {};
  waktukKu.tanggalLengkap = date + " " + month + " " + year;
  waktukKu.pukul = jam + ":" + minutes;
  waktukKu.hari = day;
  return waktukKu;
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function getWindowWidth() {
  return window.innerWidth;
}

export const formatDate = (waktu) => {
  const hari = {
    0: "Minggu",
    1: "Senin",
    2: "Selasa",
    3: "Rabu",
    4: "Kamis",
    5: "Jumat",
    6: "Sabtu",
  };

  const bulan = {
    0: "Januari",
    1: "Februari",
    2: "Maret",
    3: "April",
    4: "Mei",
    5: "Juni",
    6: "Juli",
    7: "Agustus",
    8: "September",
    9: "Oktober",
    10: "November",
    11: "Desember",
  };

  const bulan2 = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "Mei",
    5: "Jun",
    6: "Jul",
    7: "Agu",
    8: "Sep",
    9: "Okt",
    10: "Nov",
    11: "Des",
  };
  var tanggal = new Date(waktu);
  var month = bulan2[tanggal.getMonth()];

  var date = tanggal.getDate().toString();
  if (date.length == 1) {
    date = "0" + date;
  }
  var year = tanggal.getFullYear();

  const tanggalLengkap = date + " " + month + " " + year;

  return tanggalLengkap;
};

export const formatDateTime = (waktu) => {
  const hari = {
    0: "Minggu",
    1: "Senin",
    2: "Selasa",
    3: "Rabu",
    4: "Kamis",
    5: "Jumat",
    6: "Sabtu",
  };

  const bulan = {
    0: "Januari",
    1: "Februari",
    2: "Maret",
    3: "April",
    4: "Mei",
    5: "Juni",
    6: "Juli",
    7: "Agustus",
    8: "September",
    9: "Oktober",
    10: "November",
    11: "Desember",
  };

  const bulan2 = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "Mei",
    5: "Jun",
    6: "Jul",
    7: "Agu",
    8: "Sep",
    9: "Okt",
    10: "Nov",
    11: "Des",
  };
  var tanggal = new Date(waktu);
  var month = bulan2[tanggal.getMonth()];
  var day = hari[tanggal.getDay()];

  var date = tanggal.getDate().toString();
  if (date.length == 1) {
    date = "0" + date;
  }
  var year = tanggal.getFullYear();
  var jam = tanggal.getHours().toString();

  if (jam.length == 1) {
    jam = "0" + jam;
  }

  var minutes = tanggal.getMinutes().toString();
  if (minutes.length == 1) {
    minutes = "0" + minutes;
  }

  var waktukKu =
    day +
    ", " +
    date +
    " " +
    month +
    " " +
    year +
    " pukul : " +
    jam +
    ":" +
    minutes;

  return waktukKu;
};
export const substractDates = (date) => {
  var now = new Date();
  var tanggal = now.getDate();
  var bulan = now.getMonth() + 1;
  var tahun = now.getFullYear();
  var d1 = new Date(bulan + "/" + tanggal + "/" + tahun);
  var dateKu = new Date(date);
  var days = Math.floor(Math.abs(d1 - dateKu) / 1000 / 60 / 60 / 24);

  var keterangan = "";

  if (days < 1) {
    keterangan = "hari ini";
  } else if (days > 0 && days < 8) {
    keterangan = days + " hari lalu";
  } else {
    var waktu = dateTime(date);
    keterangan = waktu.tanggalLengkap;
  }

  var result = {};
  result.days = days;
  result.keterangan = keterangan;

  return result;
};

export const isValidEmail = (value) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const validateEmail = (value, setEmailError) => {
  if (value == "") {
    setEmailError("");
  } else if (isValidEmail(value)) {
    setEmailError("");
  } else {
    setEmailError("Invalid Email");
  }
};

export const validatePassword = (value, setPasswordError) => {
  if (value.length < 9) {
    setPasswordError("Password must be 9 characters");
  } else {
    setPasswordError("");
  }
};

export const validateNik = (value, setNikError) => {
  if (value.length != 16) {
    setNikError("harus 16 characters");
  } else {
    setNikError("");
  }
};

export const validateStok = (value, setStokError) => {
  if (value.length != 16) {
    setStokError("harus 16 characters");
  } else {
    setStokError("");
  }
};

export const toSmallCaps = (text) => {
  return text.toLowerCase().replace(/(?:^|\s)\S/g, (char) => {
    return char.toUpperCase();
  });
};

export const toProperCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const downloadFile = (url) => {
  // Membuat elemen <a> baru
  const a = document.createElement("a");
  a.href = url;

  // Menentukan nama file yang akan didownload, opsional.
  // Jika Anda tahu nama file atau ingin memberi nama khusus, tambahkan baris berikut:
  // a.download = 'namaFile.doc';

  // Menambahkan elemen ke DOM
  document.body.appendChild(a);

  // Memicu klik untuk memulai proses download
  a.click();

  // Menghapus elemen dari DOM setelah download dimulai
  document.body.removeChild(a);
};

export const logApp = async (uid, page) => {
  const data = { uid: uid, page: page };
  try {
    await axios.post(LOG_APP, data, {
      credentials: "include",
    });
  } catch (error) {
    swal("Failed", "jaringan Error ", "error");
  }
};

export const refreshToken = async (history, setAccessToken) => {
  try {
    const response = await axios.get(ADMINS_TOKEN, {
      credentials: "include",
    });
    setAccessToken(response.data.accessToken);
  } catch (error) {
    logoutUser(history);
  }
};

export function timeDifference(dateString) {
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (
    currentDate.getFullYear() === givenDate.getFullYear() &&
    currentDate.getMonth() === givenDate.getMonth() &&
    currentDate.getDate() === givenDate.getDate()
  ) {
    return `hari ini ${givenDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${givenDate.getMinutes().toString().padStart(2, "0")}`;
  } else {
    return `${givenDate.getDate()} ${
      monthNames[givenDate.getMonth()]
    } ${givenDate.getFullYear()} ${givenDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${givenDate.getMinutes().toString().padStart(2, "0")}`;
  }
}
