import React from "react";

const Footer = () => {
  const footerStyle = {
    backgroundColor: "rgba(40, 44, 52, 0.5)", // Warna latar belakang transparan 50%
    color: "white", // Warna teks putih
    textAlign: "center", // Posisi teks di tengah
    padding: "10px 20px", // Padding
    position: "relative", // Posisi relatif
    bottom: "0", // Posisi di bagian bawah
    width: "100%", // Lebar penuh
  };

  return (
    <footer style={footerStyle}>
      <p>© 2023 DKISP Kab. Rote Ndao</p>
      <p>Alamat | Komplek Perkantoran Civic Center, Rote Ndao</p>
      <p>Follow us on [Media Sosial]</p>
      {/* Anda dapat menambahkan lebih banyak konten di sini sesuai kebutuhan */}
    </footer>
  );
};

export default Footer;
