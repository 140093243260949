import { checkLogin } from "actions/AuthAction";
import { loginUser } from "../actions/AuthAction";
import React, { useEffect, useState } from "react";
//import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  CardTitle,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import SearchBar from "components/SearchBar/SearchBar";
import { Line, Bar } from "react-chartjs-2";
import classNames from "classnames";
import swal from "sweetalert";
import Logo from "../assets/img/logorote.png";

import Wall from "../assets/img/wall.png";
import Map from "../components/am4chartMap/am4chartMap";
import Header from "components/Header/Header";
import Footer_New from "components/Footer-New/Footer-New";
import { GET_DATA_TERAKHIR } from "utils";
import { API_TIMEOUT } from "utils";
import axios from "axios";
import { formatDate } from "utils/func";
import { toProperCase } from "utils/func";
import { GET_DATA_TERBANYAK } from "utils";
import { GET_DATA_MINTA } from "utils";
import CardLandingPage from "components/CardLandingPage/CardLandingPage";
import Download from "../assets/img/folder.png";

function LandingPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dataTerakhir, setDataTerakhir] = useState([]);
  const [dataTerbanyak, setDataTerbanyak] = useState([]);
  const [dataMinta, setDataMinta] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const domain = "https://satudata.rotendaokab.go.id/uploads/";

  const [lebarJendela, setLebarJendela] = useState(window.innerWidth);

  // const { loginLoading, loginResult, loginError, checkLoginResult } =
  //   useSelector((state) => state.AuthReducer);

  useEffect(() => {
    checkLogin(history);
  }, []);

  function handleChange(event) {
    setEmail(event.target.value);
  }

  function handleSubmit(event) {
    setLoginProses(true);
    event.preventDefault();

    if (email && password) {
      //action login
      //loginAdmin();
      loginUser(email, password, setLoginProses, history);
    } else {
      swal("Failed", "Maaf Email dan Password Harus Diisi", "error");
    }
  }

  function reportWindowSize() {
    setLebarJendela(window.innerWidth);
  }

  window.addEventListener("resize", reportWindowSize);
  // const dispatch = useDispatch();
  const history = useHistory();

  const [loginProses, setLoginProses] = useState(false);

  // const { loginLoading, loginResult, loginError, checkLoginResult } =
  //   useSelector((state) => state.AuthReducer);

  useEffect(() => {
    checkLogin(history);
    ambilDataTerakhir();
    ambilDataTerbanyak();
    ambilDataMinta();
  }, []);

  const openPDF = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  function handleChange(event) {
    setEmail(event.target.value);
  }

  function handleSubmit(event) {
    setLoginProses(true);
    event.preventDefault();

    if (email && password) {
      //action login
      //loginAdmin();
      loginUser(email, password, setLoginProses, history);
    } else {
      swal("Failed", "Maaf Email dan Password Harus Diisi", "error");
    }
  }

  const ambilDataTerakhir = (id) => {
    axios({
      method: "get",
      url: GET_DATA_TERAKHIR,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setDataTerakhir(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilDataTerbanyak = (id) => {
    axios({
      method: "get",
      url: GET_DATA_TERBANYAK,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setDataTerbanyak(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilDataMinta = (id) => {
    axios({
      method: "get",
      url: GET_DATA_MINTA,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setDataMinta(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  // useEffect(() => {
  // if (checkLoginResult) {
  //   history.push('/admin/dashboard');
  // }

  //   if (loginResult) {
  //     history.push('/admin/dashboard');
  //   }
  // }, [loginResult]);

  return (
    <>
      <Header setModalOpen={setModalOpen} />
      <div className="content" style={{ margin: 20 }}>
        <Row>
          <Col xs="12">
            <Card
              className="card-chart"
              style={{
                position: "relative",
                left: lebarJendela < 800 ? -200 : 0,
              }}
            >
              <div style={{ position: "absolute", bottom: 20, left: 20 }}>
                <SearchBar />
              </div>
              <img
                style={{
                  height: 400,
                  width: "auto", // Lebar otomatis untuk menjaga aspect ratio gambar
                  objectFit: "cover", // Menutupi area container tanpa mengubah aspect ratio
                  display: "block",
                  objectPosition: "left",
                }}
                src={Wall}
              />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg="4" md="12">
            <Card className="card-tasks">
              <CardHeader>
                <CardTitle>
                  Data Terbaru{" "}
                  <Badge color="warning">{dataTerakhir.length}</Badge>
                </CardTitle>
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem href="#pablo">Pdf</DropdownItem>
                    <DropdownItem href="#pablo">Excel</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody style={{ overflowY: "auto" }}>
                <Table>
                  <tbody>
                    {dataTerakhir.map((item) => (
                      <tr>
                        <td>
                          <p className="title">{item.dokumen}</p>
                          <p className="text-muted">
                            {toProperCase(item.perangkatdaerah)}
                          </p>
                          <p className="text-muted">
                            {formatDate(item.createdAt)}
                          </p>
                        </td>
                        <td>
                          <img
                            style={{ width: 30, cursor: "pointer" }}
                            src={Download}
                            onClick={() => openPDF(domain + item.link)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Top <Badge color="warning">{dataTerbanyak.length}</Badge>{" "}
                  Perangkat Daerah yang Berkontribusi
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table>
                  <tbody>
                    {dataTerbanyak.map((item) => (
                      <tr>
                        <td>
                          <p className="text-muted">
                            {toProperCase(item.perangkatdaerah)}
                          </p>
                        </td>
                        <td>
                          <p style={{ color: "red" }}>
                            {item.total_dokumen} dokumen
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Permintaan data <Badge color="warning"></Badge>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table>
                  <tbody>
                    {dataMinta.map((item) => (
                      <tr>
                        <td>
                          <p className="text-muted">{item.dokumen}</p>
                          <p className="text-muted">
                            {toProperCase(item.perangkatdaerah)}
                          </p>
                        </td>
                        <td>
                          <p style={{ color: "red" }}>{1} kali</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <Card
              className="card-chart"
              style={{ padding: 40, position: "relative" }}
            >
              <Map />
              <Input
                type="select"
                style={{ position: "absolute", top: 20, right: 20, width: 150 }}
              >
                <option>- pilih data - </option>
                <option>Stunting</option>
                <option>Stunting</option>
              </Input>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <CardLandingPage />
          </Col>
        </Row>

        <Modal
          size="sm"
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
          color="black"
        >
          <ModalBody>
            <Row>
              <Col>
                <img
                  src={Logo}
                  className="rounded mx-auto d-block"
                  alt="logo"
                  style={{ width: 70, marginBottom: 20 }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ marginTop: -10 }}>Kabupaten Rote Ndao</span>
                </div>
                <Card>
                  <CardHeader tag="h4">
                    SATUDATA -{" "}
                    <span style={{ fontSize: 12 }}>
                      {" "}
                      Rote Ndao Satu Data Untuk Pembangunan Berkelanjutan
                    </span>
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={(event) => handleSubmit(event)}>
                      <FormGroup>
                        <Label for="email">Email Address</Label>
                        <Input
                          type="text"
                          name="text"
                          value={email}
                          placeholder="Enter Email"
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="password">Password</Label>
                        <Input
                          type="password"
                          name="password"
                          value={password}
                          placeholder="Enter Password"
                          onChange={(event) => setPassword(event.target.value)}
                        />
                      </FormGroup>
                      {loginProses ? (
                        <Button color="primary" type="submit" disabled>
                          <Spinner size="sm" color="light" /> Loading
                        </Button>
                      ) : (
                        <Button color="primary" type="submit">
                          Login
                        </Button>
                      )}
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
      <Footer_New />
    </>
  );
}

export default LandingPage;
