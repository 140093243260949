export const API_SATUDATA = "https://satudata.rotendaokab.go.id/api/";
//'http://localhost:4000/api/';
//'https://satudata.rotendaokab.go.id/api/';
export const PERANGKAT_DAERAH = API_SATUDATA + "pd/";
export const ANALISIS_JABATAN = API_SATUDATA + "anjab/";

export const EFEKTIFITAS = API_SATUDATA + "efektifitas/";

export const DOKUMEN = API_SATUDATA + "dokumen/";

export const ADMINS_LOGIN = API_SATUDATA + "admin/login";
export const ADMINS_LOGOUT = API_SATUDATA + "admin/logout";
export const ADMINS_TOKEN = API_SATUDATA + "admin/token";
export const POST_ANJAB = API_SATUDATA + "post/anjab/";
export const GET_ANJAB = API_SATUDATA + "progress/anjab/";

export const GET_DOKUMEN = API_SATUDATA + "dokumen/ambil/";

export const ANALISIS_BEBAN_KERJA = API_SATUDATA + "abk/";
export const POST_ABK = API_SATUDATA + "post/abk/";
export const GET_ABK = API_SATUDATA + "progress/abk/";

export const USERS = API_SATUDATA + "users/";
export const LOG_APP = API_SATUDATA + "logapp/";
export const GET_NOTIF = API_SATUDATA + "notifikasi/";

export const GET_DATA_TERAKHIR = API_SATUDATA + "landingpage/data/terakhir";

export const GET_DATA_TERBANYAK = API_SATUDATA + "landingpage/data/terbanyak";
export const GET_DATA_MINTA = API_SATUDATA + "landingpage/data/mintadata";

export const GET_AKTIVITY = API_SATUDATA + "useraktivity/";
export const GET_DOKUMEN_ALL = API_SATUDATA + "dokumen/all";
export const POST_DOKUMEN = API_SATUDATA + "dokumen/input";

export const API_TIMEOUT = 120000;

export const DOMAIN = "https://satudata.rotendaokab.go.id/uploads/";
