/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Progress,
} from "reactstrap";

import axios from "axios";
import { useState, useEffect } from "react";
import { PERANGKAT_DAERAH, API_TIMEOUT } from "utils";
import { toProperCase } from "utils/func";
import { useHistory } from "react-router-dom";
import { logApp } from "utils/func";
import { refreshToken } from "utils/func";
import { ANALISIS_BEBAN_KERJA } from "utils";

function ProgresUpload() {
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [id_unit, setId_Unit] = useState("");
  const [kebutuhan, setKebutuhan] = useState("");
  const [ekisting, setEksisting] = useState("");
  const [perangkatdaerah, setPerangkatDaerah] = useState("");
  const history = useHistory();

  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        logApp(user.uid, "Peta Jabatan");
        setCurrentUser(user);
      } else {
        history.push("/login");
      }
    }
    refreshToken(history, setAccessToken);
  }, []);

  useEffect(() => {
    if (currentUser && accessToken) {
      ambilPerangkatDaerah();
    }
  }, [currentUser, accessToken]);

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: "get",
      url: ANALISIS_BEBAN_KERJA,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          const data = response.data.data;
          setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const onModalClose = () => {
    setId_Unit("");
    setPerangkatDaerah("");
    setEksisting("");
    setKebutuhan("");
    setModalOpen(false);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Peta Jabatan</CardTitle>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Perangkat Daerah</th>
                      <th>Dokumen Upload</th>
                      <th>Total</th>
                      <th className="text-center">Persentase</th>
                      {currentUser && currentUser.status == 1 ? (
                        <th>Action</th>
                      ) : (
                        <div />
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {tabelPerangkatDaerah &&
                      tabelPerangkatDaerah.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{toProperCase(item.nama)}</td>
                          <td>{item.match_count}</td>
                          <td
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            {14}
                          </td>
                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {Math.round((item.match_count / 14) * 100)}%
                                    terpenuhi{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.match_count / 14) * 100 > 50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={(item.match_count / 14) * 100}
                                />
                              </div>
                            </div>
                          </td>
                          {currentUser && currentUser.status == 1 ? (
                            <td>
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => {
                                  setId_Unit(item.id_unit);
                                  setPerangkatDaerah(item.nama);
                                  setEksisting(item.total_eksisting);
                                  setKebutuhan(item.total_kebutuhan);
                                  setModalOpen(true);
                                }}
                              >
                                Edit
                              </Button>
                            </td>
                          ) : (
                            <div />
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size="md"
          isOpen={modalOpen}
          toggle={() => onModalClose()}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => onModalClose()}>
            <div>
              <div className="headline-2">
                Edit Peta Jabatan {toProperCase(perangkatdaerah)}
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label lg="4" className="text-md-right">
                Kebutuhan Jabatan
              </Label>
              <Col lg="8">
                <Input
                  id="upload"
                  type="text"
                  value={kebutuhan}
                  onChange={(e) => setKebutuhan(e.target.value)}
                  style={{ color: "black" }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg="4" className="text-md-right">
                Jabatan Eksisting
              </Label>
              <Col lg="8">
                <Input
                  id="upload"
                  type="text"
                  value={ekisting}
                  onChange={(e) => setEksisting(e.target.value)}
                  style={{ color: "black" }}
                />
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => onModalClose()}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              <Button className="btn-rounded" color="primary" size="sm">
                {" "}
                {"Simpan"}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default ProgresUpload;
