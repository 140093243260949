import React, { useState, useEffect } from "react";

import Dok from "../../assets/img/documentation.png";
import Permintaan from "../../assets/img/emailku.png";
import Download from "../../assets/img/folder.png";

// Hook untuk mendapatkan ukuran window
const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return size;
};

// Komponen Card
const Card = ({ children }) => {
  const [width] = useWindowSize();
  const responsiveWidth = width < 768 ? "100%" : "100%"; // Responsif: full width pada layar kecil, 50% untuk lebih besar

  const cardStyle = {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "20px",

    width: responsiveWidth, // Gunakan ukuran responsif
    margin: "20px auto", // Tambahkan 'auto' untuk centering
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    transition: "width 0.3s", // Animasi perubahan lebar
  };

  return <div style={cardStyle}>{children}</div>;
};

// Komponen CardItem
const CardItem = ({ icon, mainText, subText }) => {
  const cardItemStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // Baru: rata kiri dan kanan
    borderBottom: "1px solid #e9ecef", // Garis pemisah
    padding: "10px 0", // Padding atas dan bawah untuk garis
  };

  const iconStyle = {
    width: "40px", // Atur lebar sesuai kebutuhan
    height: "40px", // Atur tinggi sesuai kebutuhan
    backgroundColor: "#e9ecef", // Warna latar belakang untuk ikon
    borderRadius: "50%", // Membuat ikon bulat
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const mainTextStyle = {
    fontWeight: "bold",
    fontSize: "18px", // Atur ukuran font sesuai kebutuhan
  };

  const subTextStyle = {
    color: "#6c757d", // Warna font untuk teks sub
  };

  return (
    <div style={cardItemStyle}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {" "}
        {/* Baru: container untuk ikon dan teks */}
        <div style={iconStyle}>{icon}</div>
        <div>
          <div style={mainTextStyle}>{mainText}</div>
          <div style={subTextStyle}>{subText}</div>
        </div>
      </div>
      <div>
        {" "}
        {/* Container untuk angka atau informasi tambahan */}
        {/* Tambahkan elemen tambahan di sini jika diperlukan */}
      </div>
    </div>
  );
};

// Komponen utama App
const CardLandingPage = () => {
  return (
    <div>
      <h1>Statistik</h1> {/* Judul yang sesuai dengan gambar yang diunggah */}
      <Card>
        <CardItem
          icon={
            <img
              src={Dok}
              alt="Printer Icon"
              style={{ width: "30px", height: "30px" }}
            />
          }
          mainText="Jumlah Dokumen"
          subText="127"
        />
        <CardItem
          icon={
            <img
              src={Permintaan}
              alt="Envelope Icon"
              style={{ width: "30px", height: "30px" }}
            />
          }
          mainText="Jumlah Permohonan Informasi"
          subText="5"
        />
        <CardItem
          icon={
            <img
              src={Download}
              alt="Download Icon"
              style={{ width: "30px", height: "30px" }}
            />
          }
          mainText="Jumlah Unduhan"
          subText="12"
        />
      </Card>
    </div>
  );
};

export default CardLandingPage;
